import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import MainContent from '../components/mainContent';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.strapi.page.title}></SEO>
      <Hero options={data.strapi.page.hero}></Hero>
      <MainContent options={data.strapi}></MainContent>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    strapi {
      page(id: "5e519d4f179058821edf00a9") {
        title
        hero {
          id
          image {
            url
          }
          heading
          rich_text_heading
          id
          cta {
            id
            link
            internal_link
            position
            text
          }
        }
        main {
          __typename
          ... on Strapi_ComponentHomepageHomepageMainFeature {
            id
            heading_quote
            quotee
            intro_rich_text
            profile_picture {
              url
            }
            signature {
              role
              name
            }
          }
          ... on Strapi_ComponentGenericMediaObject {
            id
            heading
            body_rich_text
            media_alignment: alignment
            image_desktop {
              id
              url
            }
            image_mobile {
              id
              url
            }
            background_theme
            date_posted
          }
          ... on Strapi_ComponentGenericHeadingComponent {
            id
            heading
            stand_first
            stand_first_rich_text
            heading_alignment: alignment
            theme
            padding_bottom
          }
          ... on Strapi_ComponentGenericInfoCardSection {
            id
            Info_card {
              type
              info_heading
              info_content
            }
          }
        }
      }
    }
  }
`;
